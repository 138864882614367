<template>
  <div class="home">
    <img alt="DK logo" src="../assets/logo.png" style="width: 20em;">
    <HelloWorld msg="若仍存梦想"/>
    <HelloWorld msg="请努力实现它"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
<style>
.home{
  margin: auto;
}
</style>