<template>
  <nav>
    <router-link to="/">首页</router-link> |
    <router-link to="/software">软件</router-link> |
    <router-link to="/photography">摄影</router-link> |
    <router-link to="/note">笔记</router-link> |
    <router-link to="/about">关于</router-link>
  </nav>
  <router-view/>
  <footer>
    <p class="footerba"></p>
    <a href="https://beian.miit.gov.cn" target="_blank" style="text-decoration:none;"><p>粤ICP备2024170376号</p></a>
    <p class="footerfg">|</p> 
    <img src="./assets/beian.png" alt="备案图标" style="width:1em;height:1em;margin-top: auto;margin-bottom: auto;" >
    <a href="https://beian.mps.gov.cn/#/query/webSearch?code=44060502003550" rel="noreferrer" target="_blank" style="text-decoration:none;"><p>粤公网安备44060502003550号</p></a>
    <p class="footerfg">|</p>
    <p >@深水</p>
    <p class="footerba"></p>
  </footer>
</template>
<script setup>

</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
*{
  box-sizing: border-box;
}
nav {
  padding: 1em;
  background-color: #457B9D;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #ffffff;
}
footer {
  margin-top: auto;
  width: 100%;
  display: flex;
  background-color: #457B9D;
  p{
    color: #ffffff;
  }
  .footerba {
    margin: auto;
  }
  .footerfg{
    margin-left: 1em;
    margin-right: 1em;
  }
}
body,html{
  height: 100%;
  margin: 0;
}
a{
  text-decoration:none
}
.errorMsg{
  color: tomato;
}
</style>
