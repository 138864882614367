import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: '若仍存梦想' }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { title: 'DK关于' }
  },
  {
    path: '/software',
    name: 'software',
    component: () => import(/* webpackChunkName: "about" */ '../views/software/SoftwareView.vue'),
    meta: { title: 'DK软件' }
  },
  {
    path: '/note',
    name: 'note',
    component: () => import(/* webpackChunkName: "about" */ '../views/note/NoteView.vue'),
    meta: { title: 'DK笔记' }
  },
  {
    path: '/photography',
    name: 'photography',
    component: () => import(/* webpackChunkName: "about" */ '../views/photography/PhotographyView.vue'),
    meta: { title: 'DK摄影' }
  },{
    path: '/jsonEditor',
    name: 'jsonEditor',
    component: () => import(/* webpackChunkName: "about" */ '../views/software/JsonEditorView.vue'),
    meta: { title: 'DKJSON' }
  },{
    path: '/xmlEditor',
    name: 'xmlEditor',
    component: () => import(/* webpackChunkName: "about" */ '../views/software/XmlEditorView.vue'),
    meta: { title: 'DKXML' }
  },{
    path: '/openBlack',
    name: 'openBlack',
    component: () => import(/* webpackChunkName: "about" */ '../views/software/OpenBlackView.vue'),
    meta: { title: 'OpenBlac' }
  },
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
});
router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title
  next()
})
export default router
